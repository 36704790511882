
.modal-content {
    padding: 20px;
}
.modal-content ul li {
    padding: 8px 12px !important;
    margin-bottom: 5px;
}
/* Style the modal backdrop (background overlay) */
.modal-backdrop {
   /* background-color: rgba(0, 0, 0, 0.5);  Semi-transparent black background */
    background-color: rgba(52, 2, 2, 0.985); /* Semi-transparent black background */
  }
  
  /* Style the modal content */
  .modal-content {
    background-color: #1e0101f3; 
    border-radius: 10px; /* Rounded corners */
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2); /* Shadow */
    padding: 20px;
  }
  
  /* Style the modal title */
  .modal-title {
    color: #841111; /* Blue color for the title */
  }
  
  /* Style the modal body */
  .modal-body {
    font-size: 16px; /* Font size for the body text */
  }
  
  .colorful-list-item {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0.25rem 0;
    border-bottom: 1px solid #181010;
    background-color: #453838;
    border-radius: 5px;
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);
  }
  
  /* Custom styles for the colorful headers */
  .colorful-header {
    font-weight: bold;
    background-color: #ACFADF;
    color: #313866;
  }

  .rank-column {
    width: 0px;
    text-align: right;
    padding-right: 0px;
}

  
  